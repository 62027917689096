import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import { Elements, StripeProvider } from 'react-stripe-elements';
import Html5DragDropBackend from 'react-dnd-html5-backend'
import axios from 'axios'
import { StoreProvider } from 'store'
import { DndProvider as DragDropProvider } from 'react-dnd'

import * as routes from '../../constants/routes'
import { pathAfterSignIn } from '../../utils/local_storage'
import { setPrivileges } from '../../utils/auth'
import { registerModels } from '../../models'
import configureAxios from '../../utils/axios'
import withTracker from '../withTracker'
import { HistoryExtractor } from './history'

import ResetPassword from '../users/reset_password'
import Dashboard from '../dashboard'
import ConfirmEmail from '../users/confirm_email'
import SignIn from '../users/sign_in'
import SamlSignIn from '../users/sign_in/saml_idp'
import ForgotPassword from '../users/forgot_password'
import NotFound from '../general/not_found'
import SignedInBar from '../general/signed_in_bar'
import CreateJob from '../jobs/create'
import JobBuilderV3Step12 from '../jobs/create/v3_step_1_2_vitals_freelancers'
import JobBuilderV3Step3 from '../jobs/create/v3_step_3_briefing'
import JobBuilderV3Step4 from '../jobs/create/v3_step_4_finalize_team'
import ShowJob from '../jobs/show'
import HandleJobAccessLink from '../job_access_links/show'
import JobList from '../jobs/list'
import PrintJob from '../jobs/show/print'
import PrintIdea from '../jobs/show/print_idea'
import CustomerOnboarding from '../customer_onboarding'
import GenericUserOnboarding from '../generic_user_onboarding'
import TeamMemberOnboarding from '../customer_onboarding/team'
import ExpertOnboarding from '../expert_onboarding'
import Settings from '../settings'
import NotificationSettings from '../notification_settings'
import EditProfile from '../profile/edit'
import ManageOrganization from '../general/organization'
import MasterCommandCenter from '../general/master_command_center';
import ManageBilling from '../general/organization/billing'
import ManageIdeasicleExperts from '../general/manage_ideasicle_experts'
import AdminReviewPaymentsJob from '../jobs/show/admin/review_payments'
import InviteTalents from '../invite_talents'
import TalentPool from '../talent_pool'

import './global.scss'
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'css-spinners/dist/all-spinner.css'
import 'react-toastify/dist/ReactToastify.css';

configureAxios(axios)
registerModels()
setPrivileges()
toast.configure({ toastClassName: 'ix-toast', position: toast.POSITION.TOP_CENTER})
if(window.errorToast) {
  setTimeout(() => toast.warn(window.errorToast), 10);
}

// If we were previously tracking a redirect from an authorized
// page to ask the user to sign in first, clear that.
//
// Sign in will go to default location.
pathAfterSignIn.clear()

const ResetPasswordWithTracker = withTracker(ResetPassword)
const ConfirmEmailWithTracker = withTracker(ConfirmEmail)
const SignInWithTracker = withTracker(SignIn)
const SamlSignInWithTracker = withTracker(SamlSignIn)
const ForgotPasswordWithTracker = withTracker(ForgotPassword)
const CustomerOnboardingWithTracker = withTracker(CustomerOnboarding)
const ExpertOnboardingWithTracker = withTracker(ExpertOnboarding)
const GenericUserOnboardingWithTracker = withTracker(GenericUserOnboarding)
const DashboardWithTracker = withTracker(Dashboard)
const EditProfileWithTracker = withTracker(EditProfile)
const CreateJobWithTracker = withTracker(CreateJob)
const ShowJobWithTracker = withTracker(ShowJob)
const HandleJobAccessLinkWithTracker = withTracker(HandleJobAccessLink)
const JobListWithTracker = withTracker(JobList)
const NotFoundWithTracker = withTracker(NotFound)
const SettingsWithTracker = withTracker(Settings)
const NotificationSettingsWithTracker = withTracker(NotificationSettings)
const ManageOrganizationWithTracker = withTracker(ManageOrganization)
const MasterCommandCenterWithTracker = withTracker(MasterCommandCenter)
const TeamMemberOnboardingWithTracker = withTracker(TeamMemberOnboarding)
const ManageBillingWithTracker = withTracker(ManageBilling)
const ManageIdeasicleExpertsWithTracker = withTracker(ManageIdeasicleExperts)
const AdminReviewPaymentsJobWithTracker = withTracker(AdminReviewPaymentsJob)
const InviteTalentsWithTracker = withTracker(InviteTalents)
const TalentPoolWithTracker = withTracker(TalentPool)

const year = new Date().getFullYear();

const App = () => {
  const [stripe, setStripe] = useState(null)
  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(window.stripe_publishable_key))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        setStripe(window.Stripe(window.stripe_publishable_key))
      })
    }
  }, [])


  return (
    <div id="page">
      <Helmet>
        <title>Ideasicle X</title>
      </Helmet>
      <div id="page-content">
        <StripeProvider stripe={stripe}>
          <Elements>
            <StoreProvider>
        			<DragDropProvider backend={Html5DragDropBackend}>
                <Router>
                  <Switch>
                    <Route path={`${routes.RESET_PASSWORD}`} component={ResetPasswordWithTracker} />

                    <Route exact path={[routes.TOU, routes.TOUv2]} render={() => {
                      window.location.replace("https://www.ideasiclex.com/terms-of-use ")
                    }} />
                    <Route exact path={routes.PRIVACY_POLICY} render={() => {
                      window.location.replace("https://www.ideasiclex.com/privacy-policy")
                    }} />
                    <Route exact path={[routes.EXPERT_AGREEMENT, routes.FREELANCER_AGREEMENT]} render={() => {
                      window.location.replace("https://www.ideasiclex.com/freelancer-consulting-agreement")
                    }} />

                    <Route exact path={routes.CONFIRM_EMAIL} component={ConfirmEmailWithTracker} />
                    <Route exact path={routes.SIGN_IN} component={SignInWithTracker} />
                    <Route exact path={routes.SAML_SIGN_IN} component={SamlSignInWithTracker} />
                    <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPasswordWithTracker} />

                    <Route exact path={routes.CUSTOMER_ONBOARDING} component={CustomerOnboardingWithTracker} />
                    <Route exact path={routes.TEAM_MEMBER_ONBOARDING} component={TeamMemberOnboardingWithTracker} />
                    <Route exact path={[routes.EXPERT_ONBOARDING, routes.FREELANCER_ONBOARDING]} component={ExpertOnboardingWithTracker} />
                    <Route exact path={[routes.GENERIC_USER_ONBOARDING]} component={GenericUserOnboardingWithTracker} />

                    <Route path={routes.IDEA_PRINT(':jobId', ':ideaId', ':index')} component={PrintIdea} />

                    <SignedInBar>
                      <Switch>
                        <Route exact path="/" component={DashboardWithTracker} />
                        <Route path={routes.EDIT_PROFILE} component={EditProfileWithTracker} />
                        <Route path={routes.NOTIFICAITON_SETTINGS} component={NotificationSettingsWithTracker} />
                        <Route path={routes.SETTINGS} component={SettingsWithTracker} />
                        <Route exact path={[routes.JOB_BUILDER_STEP_1_VITALS, routes.JOB_BUILDER_STEP_2_FREELANCERS]} component={JobBuilderV3Step12} />
                        <Route exact path={routes.JOB_BUILDER_STEP_3_BRIEFING} component={JobBuilderV3Step3} />
                        <Route exact path={routes.JOB_BUILDER_STEP_4_FINALIZE_TEAM} component={JobBuilderV3Step4} />
                        <Route exact path={routes.CREATE_JOB_V1} component={CreateJobWithTracker} />

                        <Route path={routes.JOB_PRINT(':jobId')} component={PrintJob} />
                        <Route path={routes.JOB_LIST} component={JobListWithTracker} />

                        <Route path={routes.ADMIN_APPROVE_JOB_PAYMENT(':jobId')} component={AdminReviewPaymentsJobWithTracker} />
                        <Route path={routes.JOB(':jobId')} component={ShowJobWithTracker} />
                        <Route path={routes.JOB_DIRECT_ACCESS(':jobAccessLinkId')} component={HandleJobAccessLinkWithTracker} />
                        

                        <Route path={routes.MASTER_COMMAND_CENTER} component={MasterCommandCenterWithTracker} />
                        <Route path={routes.MANAGE_ORGANIZATION} component={ManageOrganizationWithTracker} />
                        <Route path={routes.MANAGE_BILLING} component={ManageBillingWithTracker} />

                        <Route path={routes.ADMIN_MANAGE_IDEASICLE_EXPERTS} component={ManageIdeasicleExpertsWithTracker} />
                        <Route path={routes.INVITE_TALENTS} component={InviteTalentsWithTracker} />
                        <Route path={routes.TALENT_POOL} component={TalentPoolWithTracker} />
                      </Switch>
                    </SignedInBar>


                    <Route component={NotFoundWithTracker} />
                  </Switch>

                  <HistoryExtractor />
                </Router>
              </DragDropProvider>
            </StoreProvider>
          </Elements>
        </StripeProvider>
      </div>
      <footer id="footer">
        	<span className="company-name"> &copy; Ideasicle X {year}.</span>
          &nbsp;
          <a href={routes.PRIVACY_POLICY} target="_blank">Privacy Policy</a>
          &nbsp;|&nbsp;
          <a href={routes.TOU} target="_blank">Terms of Use</a>
      </footer>
    </div>
  )
}

export default App
