import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useFormik } from 'formik';
import { array, object, string } from 'yup';

import expertsResource from 'resources/experts';
import { SvgBackArrow } from '../general/icons';
import styles from './styles.module.scss';

const validationSchema = object().shape({
  employees: array().of(string().email('Invalid email address')),
  freelancers: array().of(string().email('Invalid email address')),
});

const InviteTalents = ({ history }) => {
  const navigateToDashboard = () => history.push('/');

  const handleinviteAll = async (values, { setSubmitting }) => {
    try {
      const experts = [];
      values.employees.forEach((email) => !!email && experts.push({ email, profile_type: 'employee' }));
      values.freelancers.forEach((email) => !!email && experts.push({ email, profile_type: 'freelancer' }));
      await expertsResource.create({ data: { experts } });
      navigateToDashboard();
      toast.success('Your invitation was sent.');
    } catch (err) {
      toast.error('Failed to send all invitations');
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: { employees: [''], freelancers: [''] },
    validationSchema,
    onSubmit: handleinviteAll,
  });

  const handleChangeEmail = (index, talentRole) => (event) => {
    const talents = [...values[talentRole]];
    talents[index] = event.target.value;
    setFieldValue(talentRole, talents);
  };

  const handleAdd = (talentRole) => {
    setFieldValue(talentRole, [...values[talentRole], '']);
  };

  return (
    <Container>
      <div className={styles.back} onClick={navigateToDashboard}>
        <SvgBackArrow />
        <span>Back to Dashboard</span>
      </div>
      <div className={styles.header}>
        <h2>Add Talent To Your Pool</h2>
        <p>
          Add your employees and your favorite freelancers to your agency's talent pool simply by inputting their email
          addresses below. When you click "Invite All" each individual will receive an email invitation to fill out a
          profile.
        </p>
      </div>
      <div className={styles.talentPoolContainer}>
        <Row>
          <Col xs={12} className={styles.talentPoolHeader}>
            <h3 className="primaryLabel">Employees</h3>
            <p>Will not be asked to submit a W9, as they are already employees.</p>
          </Col>
          {values.employees.map((employee, index) => (
            <Col className={styles.talentInput} key={`employee-${index}`} md={4} sm={6} xs={12}>
              <input
                type="email"
                className={styles.talentEmail}
                value={employee}
                placeholder="john.doe@agency.com"
                onChange={handleChangeEmail(index, 'employees')}
              />
              {Boolean(touched.employees?.[index] && errors.employees?.[index]) && (
                <span className={styles.error}>{errors.employees?.[index]}</span>
              )}
            </Col>
          ))}
          <Col className={styles.talentInput} md={4} sm={6} xs={12}>
            <button className="btn btn-primary btn-slim" onClick={() => handleAdd('employees')}>
              Add New
            </button>
          </Col>
        </Row>
        <div className={styles.divider} />
        <Row>
          <Col xs={12} className={styles.talentPoolHeader}>
            <h3 className="primaryLabel">Freelancers</h3>
            <p>
              Will be required to submit a W9 so you can pay them. Note: your agency will have the 1099 relationship
              with any paid freelancers you invite and subsequently hire for idea jobs.
            </p>
          </Col>
          {values.freelancers.map((freelancer, index) => (
            <Col className={styles.talentInput} key={`employee-${index}`} md={4} sm={6} xs={12}>
              <input
                type="email"
                className={styles.talentEmail}
                value={freelancer}
                placeholder="john.doe@agency.com"
                onChange={handleChangeEmail(index, 'freelancers')}
              />
              {Boolean(touched.freelancers?.[index] && errors.freelancers?.[index]) && (
                <span className={styles.error}>{errors.freelancers?.[index]}</span>
              )}
            </Col>
          ))}
          <Col className={styles.talentInput} md={4} sm={6} xs={12}>
            <button className="btn btn-primary btn-slim" onClick={() => handleAdd('freelancers')}>
              Add New
            </button>
          </Col>
        </Row>
        <div className={styles.divider} />
        <button className={classnames('btn btn-primary', styles.inviteBtn)} onClick={handleSubmit}>
          Invite All
        </button>
      </div>
    </Container>
  );
};

export default InviteTalents;
